

















































































































































































































import {Component} from 'vue-property-decorator';
import {email, integer, minValue, required} from 'vuelidate/lib/validators';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import User from "@/models/User.model";
import {Gender} from "@/enum/UserTitle.enum";
import {UserDegree} from "@/enum/UserDegree";
import {AUTH_STORE_NAME, AuthStoreActions, AuthStoreGetters} from "@/store/auth.store";
import {namespace} from "vuex-class";
import IconTooltipComponent from "@/components/IconTooltip.component.vue";
import {UserType} from "@/enum/UserType.enum";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    IconTooltip: IconTooltipComponent
  },
  mixins: [validationMixin],
  validations: {
    user: {
      gender: {
        required
      },
      email: {
        required,
        email
      },
      phone: {},
      address: {
        city: {
          required
        },
        houseNr: {
          required,
        },
        street: {
          required
        },
        zip: {
          required,
          minValue: minValue(0),
          integer
        }
      },
      billingDetails: {
        iban: {
          required
        },
        bic: {
          required,
        }
      },
    },
  }
})
export default class EditUserComponent extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Getter(AuthStoreGetters.CURRENT_USER)
  private currentUser!: User;

  @AuthStore.Action(AuthStoreActions.UPDATE_USER)
  private updateUserAction!: (payload: { id: string, user: User }) => User;

  private created() {
    this.user = User.parseFromObject({...this.currentUser});
  }

  private get isChangingEmail() {
    return this.currentUser.email !== this.user.email;
  }

  /**
   * user model that is used on the inputs for storing the values
   * @private
   */
  private user: User = User.parseFromObject({});

  /**
   * different degreeOptions
   * @private
   */
  private dOptions = [UserDegree.NONE, UserDegree.DR, UserDegree.PROF, UserDegree.PHD, UserDegree.BA, UserDegree.MBA, UserDegree.PHD, UserDegree.PROFDR];

  /**
   * different gender options
   * @private
   */
  private gOptions = [Gender.MALE, Gender.FEMALE, Gender.DIVERSE, Gender.NOT_SET];

  /**
   * returns translated genders based on the gender enum
   * @private
   */
  private get genderOptions() {
    return this.gOptions.map((n: Gender) => this.$t('GENERAL.GENDER.' + n));
  }

  /**
   * flag that shows and hides the chars of the password field
   */
  private hidePassword: boolean = true;

  /**
   * Loading State bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * Check if Form is Valid
   * if valid try to Register a new Account and listen for API Responses
   * If Invalid return so Vuelidate handles Errors first
   * @private
   */
  private async changeUserInfo() {
    // Trigger validation
    this.$v.$touch();

    // Form is Invalid return here
    if (this.$v.$invalid) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
      return;
    }
    // Form is Valid try to Register a new Account
    try {
      // Set Loading to true
      this.isLoading = true;

      // logs in the user, checks if the user has the correct role
      await this.updateUserAction({id: this.currentUser._id!, user: this.user});

      // Registration Successful toast
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.USER_INFO_UPDATED');
      this.dismiss(true);

      if(this.user.email !== this.currentUser.email) {
        this.$notifyInfoSimplified('GENERAL.NOTIFICATIONS.EMAIL_CHANGE_REQUESTED', null, 10000);
      }
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 409: // Email or Company already exists
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_EMAIL_OR_COMPANY_ALREADY_EXISTS');
            break;
          case 422: // Wrong Format in Credentials
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
            break;
          case 423:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_IBAN');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_ERROR');
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }

  private get isCompany(): boolean {
    return this.user.type === UserType.COMPANY;
  }

  /**
   * Validates the current iban checks
   * @private
   */
  private validateIban(): boolean {
    // Call iban-to-bic library that is imported inside the index.html as a javascript file due to missing
    // typescript support
    let bic = ibanToBic.ibanToBic(this.user.billingDetails?.iban);

    // Set BIC in BillingDetails
    if (this.user.billingDetails) {
      this.user.billingDetails.bic = bic;
    }

    return this.triggerValidation('user.billingDetails.iban');
  }
}
