
















import {Component, Prop} from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {mixins} from 'vue-class-component';

@Component({})
export default class IconTooltipComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({ default: "Tooltip" })
  public text!: string;

  @Prop({ default: "mdi-information-outline"})
  public icon!: string;
}
